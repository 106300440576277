import {
  computed, onMounted, ref, Ref, watch,
} from 'vue';
import {
  ConstructorReportTemplateHeaderGroup,
  ConstructorVariable, ConstructorVariableGroup, HeaderGroupWithChildren, isHeaderGroup,
  ProductionType,
  useConstructor,
} from '@/hooks/useConstructor';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { CompanyIdKey } from '@/symbols';
import {
  ConstructorReportTemplate,
  ConstructorReportTemplateColumns,
  ConstructorReportTemplateHeaders,
  ConstructorReportTemplateHeaderStyle, ConstructorReportTemplateRows,
  useConstructorReportTemplates,
} from '@/hooks/useConstructorReportTemplates';
import { FormModel } from './types';
import { defaultModel } from '@/pages/exchange/export/useExchangeExportModel';

export type ConstructorReportTemplateTreeLeaf = {
  tag: 'leaf'; var: string; name: string; styles: ConstructorReportTemplateHeaderStyle & { width?: number}; no_duplicates: boolean; blank: boolean; };
export type ConstructorReportTemplateTreeGroup = { tag: 'group'; name: string; elements: ConstructorReportTemplateTreeItem[]; styles: ConstructorReportTemplateHeaderStyle };
export type ConstructorReportTemplateTreeItem = ConstructorReportTemplateTreeLeaf | ConstructorReportTemplateTreeGroup;

const isTreeLeaf = (item: any): item is ConstructorReportTemplateTreeLeaf => !item.elements;

export const mapTemplateTreeToApiPayloadFields = (
  tree: ConstructorReportTemplateTreeItem[],
  currentColumnNumber = 0,
) => tree.reduce((acc, item, i) => {
  if (isTreeLeaf(item)) {
    acc.columnNumber += 1;
    const { styles } = item;
    if (styles) {
      if (styles.width) {
        acc.columnStyles[acc.columnNumber] = { width: styles.width };
      }
      if (styles.fill || styles.alignment || styles.font) {
        acc.headerStyles[acc.columnNumber] = {
          ...(styles.fill ? { fill: styles.fill } : {}),
          ...(styles.alignment ? { alignment: styles.alignment } : {}),
          ...(styles.font ? { font: styles.font } : {}),
        };
      }
    }
    if (item.no_duplicates) {
      if (!acc.headerStyles[acc.columnNumber]) {
        acc.headerStyles[acc.columnNumber] = {};
      }
      acc.headerStyles[acc.columnNumber] = {
        ...acc.headerStyles[acc.columnNumber],
        no_duplicates: item.no_duplicates,
      };
    }
    if (item.blank) {
      if (!acc.headerStyles[acc.columnNumber]) {
        acc.headerStyles[acc.columnNumber] = {};
      }
      acc.headerStyles[acc.columnNumber] = {
        ...acc.headerStyles[acc.columnNumber],
        blank: item.blank,
      };
    }
    acc.columns.push([(item as ConstructorReportTemplateTreeLeaf).var, item.name]);
  } else {
    const {
      columns, headerGroups, headerStyles, columnStyles, columnNumber,
    } = mapTemplateTreeToApiPayloadFields(
      (item as ConstructorReportTemplateTreeGroup).elements,
      acc.columnNumber,
    );
    acc.columnNumber = columnNumber;
    acc.columns.push(...columns);
    acc.headerStyles = {
      ...acc.headerStyles,
      ...headerStyles,
    };
    acc.columnStyles = {
      ...acc.columnStyles,
      ...columnStyles,
    };
    const endGroupColumnNumber = columnNumber;
    const startGroupColumnNumber = endGroupColumnNumber - columns.length + 1;
    const { styles } = item;
    const headerCell = {
      range: [startGroupColumnNumber, endGroupColumnNumber] as [number, number],
      value: item.name,
      ...(headerGroups.length ? { subgroups: headerGroups } : {}),
      ...(styles && styles.fill ? { fill: styles.fill } : {}),
      ...(styles && styles.alignment ? { alignment: styles.alignment } : {}),
      ...(styles && styles.font ? { font: styles.font } : {}),
    };
    acc.headerGroups.push(headerCell);
  }
  return acc;
}, {
  columns: [], headerGroups: [], headerStyles: {}, columnStyles: {}, columnNumber: currentColumnNumber,
} as {
  columns: Array<[string, string]>;
  headerGroups: ConstructorReportTemplateHeaderGroup[];
  headerStyles: ConstructorReportTemplateHeaders;
  columnStyles: ConstructorReportTemplateColumns;
  columnNumber: number;
});

export const mapTemplateResponseToTemplateTree = (
  template: { columns: ConstructorReportTemplate['columns']; styles: ConstructorReportTemplate['styles'] },
) => {
  const headerGroups = (template?.styles?.group_headers) ?? [];
  const headerStyles = (template?.styles?.headers) ?? {};
  const columnStyles = (template?.styles?.columns) ?? {};
  const { columns } = template;

  if (!headerGroups.length) {
    return columns.map(([variable, name], i) => ({
      tag: 'leaf',
      var: variable,
      name,
      no_duplicates: !!headerStyles[i + 1]?.no_duplicates,
      blank: !!headerStyles[i + 1]?.blank,
      styles: {
        ...(columnStyles[i + 1] ?? {}),
        ...((() => {
          const { no_duplicates, blank, ...rest } = headerStyles[i + 1] ?? {};
          return rest;
        })()),
      },
    }) as ConstructorReportTemplateTreeItem);
  }

  const columnsWithGroups = columns
    .reduce((acc, [variable, name], columnIndex) => {
      const columnNumber = columnIndex + 1;
      const columnGroup = headerGroups.find(({ range }) => {
        const [groupStart, groupEnd] = range;
        if (columnNumber >= groupStart && columnNumber <= groupEnd) {
          return true;
        }
        return false;
      });
      if (columnGroup) {
        const foundGroup = acc.find((el) => {
          if (isHeaderGroup(el)) {
            const { range: [groupStart, groupEnd], value: groupName } = el;
            return groupName === columnGroup.value && columnNumber >= groupStart && columnNumber <= groupEnd;
          }
          return false;
        });
        if (!foundGroup) {
          acc.push(columnGroup);
        }
      } else {
        acc.push({
          tag: 'leaf',
          name,
          var: variable,
          no_duplicates: !!headerStyles[columnNumber]?.no_duplicates,
          blank: !!headerStyles[columnNumber]?.blank,
          styles: {
            ...(columnStyles[columnNumber] ?? {}),
            ...((() => {
              const { no_duplicates, blank, ...rest } = headerStyles[columnNumber] ?? {};
              return rest;
            })()),
          },
        });
      }

      return acc;
    }, [] as Array<ConstructorReportTemplateHeaderGroup|ConstructorReportTemplateTreeLeaf>);

  const recursiveTree = (
    columnsWithGroups: Array<ConstructorReportTemplateHeaderGroup|ConstructorReportTemplateTreeLeaf>,
  ) => columnsWithGroups.reduce((acc, item) => {
    if (!isHeaderGroup(item)) {
      acc.push(item);
    } else {
      if (!item.subgroups) {
        const {
          range: [groupStart, groupEnd], value: groupName, subgroups, ...styles
        } = item;
        const elements: ConstructorReportTemplateTreeLeaf[] = new Array(groupEnd - groupStart + 1)
          .fill(null)
          .map((_, index) => {
            const columnNumber = index + groupStart;
            const column = columns[columnNumber - 1];
            return {
              tag: 'leaf',
              var: column[0],
              name: column[1],
              no_duplicates: !!headerStyles[columnNumber]?.no_duplicates,
              blank: !!headerStyles[columnNumber]?.blank,
              styles: {
                ...(columnStyles[columnNumber] ?? {}),
                ...((() => {
                  const { no_duplicates, blank, ...rest } = headerStyles[columnNumber] ?? {};
                  return rest;
                })()),
              },
            };
          });

        acc.push({
          tag: 'group',
          name: groupName,
          elements,
          styles,
        });
      } else {
        const {
          range, value: groupName, subgroups, ...styles
        } = item;
        acc.push({
          tag: 'group',
          name: groupName,
          elements: recursiveTree(subgroups),
          styles,
        });
      }
    }
    return acc;
  }, [] as ConstructorReportTemplateTreeItem[]);

  return recursiveTree(columnsWithGroups);
};

export const useExportConstructor = (
  productionType: Ref<ProductionType>,
  templateTypeId: Ref<number|null>,
) => {
  const companyId = useProtectedInject(CompanyIdKey);
  const reportTemplatesApi = useConstructorReportTemplates();
  const {
    fetchConstructorVariables: fetchVars,
  } = useConstructor();

  const templates = ref<Omit<ConstructorReportTemplate, 'styles'>[]>([]);
  const templateId = ref<number|null>(null);
  const templateSettingsId = ref<number|null>(null);
  const variableGroups = ref<ConstructorVariableGroup[]>([]);
  const restStyles = ref<Record<string, any>>({});
  const headerHeights = ref<ConstructorReportTemplateRows>({});
  const templateVariablesHasBeenChanged = ref(false);
  const optionalFields = ref<any>(null);
  const payloadFields = ref<FormModel>(defaultModel());

  const templateTree = ref<ConstructorReportTemplateTreeItem[]>([]);

  const resetFormFieldsData = () => {
    templateTree.value = [];
    templateVariablesHasBeenChanged.value = false;
  };

  const variables = computed<ConstructorVariable[]>(
    () => variableGroups.value.reduce((acc, group) => {
      acc.push(...group.vars);
      return acc;
    }, [] as ConstructorVariable[]),
  );

  const fetchConstructorVariables = async () => {
    resetFormFieldsData();
    const { status, response } = await fetchVars({
      filters: {
        production_type: productionType.value,
      },
      limit: 1000,
      page: 1,
    });
    if (!status) {
      return;
    }
    variableGroups.value = response.results;
  };

  const fetchTemplates = async () => {
    templates.value = [];
    resetFormFieldsData();
    templateVariablesHasBeenChanged.value = false;
    const { status, response } = await reportTemplatesApi.template.fetchList({
      template_type: templateTypeId.value as unknown as number,
      production_type: productionType.value,
      company_id: companyId.value,
    });
    if (!status) {
      return;
    }
    templates.value = response.results;
  };

  const fetchTemplate = async () => {
    templateTree.value = [];
    if (!templateId.value) return;
    const response = await reportTemplatesApi.template.fetchItem(
      templateId.value,
    );
    if (!response.status) {
      return;
    }
    const { header_cells, ...rest } = response.response.styles;
    restStyles.value = rest;
    templateTree.value = mapTemplateResponseToTemplateTree(response.response);
    optionalFields.value = response.response.optional_fields;
    const hHeight = response.response.styles.rows;
    headerHeights.value = hHeight || {};
    await fetchTemplateSettings();
  };

  const fetchTemplateSettings = async () => {
    const { status, response } = await reportTemplatesApi.template.fetchItemSettings({
      filters: {
        company: companyId.value,
        template: templateId.value as number,
      },
      limit: 10,
      page: 1,
    });
    if (!status) return;
    if (response.results.length) {
      payloadFields.value = response.results[0].settings;
      templateSettingsId.value = response.results[0].id;
    } else {
      console.log('works here')
      payloadFields.value = defaultModel();
      templateSettingsId.value = null;
    }
  };

  onMounted(() => {
    fetchConstructorVariables();
  });

  watch(productionType, () => {
    fetchConstructorVariables();
  });

  watch([companyId, templateTypeId], () => {
    // templates.value = [];
    // templateId.value = undefined;
    templateVariablesHasBeenChanged.value = false;
  });
  watch(templateTypeId, async (v) => {
    await fetchTemplates();
    if (templates.value?.length) {
      templateId.value = templates.value[0].id;
      optionalFields.value = templates.value[0].optional_fields;
    } else {
      templateId.value = null;
    }
  });
  watch(templateId, fetchTemplate);

  const updateTemplateVariables = (list: Array<ConstructorReportTemplateTreeItem>) => {
    templateTree.value = list;
    templateVariablesHasBeenChanged.value = true;
  };

  // не вызывается, имя меняется прям в ColumnsEditor, без emit
  const updateTemplateVariable = (payload: { index: number; value: [string, string]}) => {
    // const i = templateColumns.value.findIndex(([variable]) => variable === payload.value[0]);
    // if (i !== -1) {
    //   // eslint-disable-next-line prefer-destructuring
    //   templateColumns.value[i][1] = payload.value[1];
    // }
    // templateVariablesHasBeenChanged.value = true;
  };

  const removeTemplate = (id: number, e: MouseEvent) => {
    e.stopPropagation();
    const i = templates.value.findIndex((t) => t.id === id);
    if (i !== -1) {
      templates.value.splice(i, 1);
    }
    if (id === templateId.value) {
      templateId.value = null;
    }
    reportTemplatesApi.template.removeItem(id);
    if (templateSettingsId.value) {
      reportTemplatesApi.template.removeItemSettings(templateSettingsId.value);
      templateSettingsId.value = null;
      payloadFields.value = defaultModel();
    }
    optionalFields.value = null;
  };

  return {
    templates,
    templateId,
    payloadFields,
    templateSettingsId,
    variables,
    variableGroups,
    templateTree,
    restStyles,
    headerHeights,
    updateTemplateVariables,
    updateTemplateVariable,
    templateVariablesHasBeenChanged,
    removeTemplate,
    optionalFields,
  };
};
