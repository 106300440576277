import { computed, Ref } from 'vue';

export const useExchangeImportActions = (dataType: Ref<string>) => {

  const bottomActions = computed(() => ((dataType.value === 'paymentOrder' && [
    {
      key: 'returnCertificate',
      label: 'Справка на возврат пошлины',
    },
    {
      key: 'searchByFileName',
      label: 'Загрузка только по ЛС / Договору (PDF)',
    },
    {
      key: 'enableDuplicatesSearch',
      label: 'Поиск дублей',
    },
    {
      key: 'ignoreFeeAmount',
      label: 'Не учитывать сумму пошлины',
    },
    {
      key: 'ignoreFullName',
      label: 'Игнорировать ФИО',
    },
    {
      key: 'ignorePersonalAccount',
      label: 'Игнорировать номер ЛС / Договор',
    },
    {
      key: 'ignoreStatuses',
      label: 'Игнорировать статусы',
    },
    {
      key: 'ignoreDetails',
      label: 'Игнорировать реквизиты',
    },
    {
      key: 'repaid',
      label: 'Доплата пошлины',
    },
  ]) || (dataType.value === 'egrn' && [
    {
      key: 'searchByFileName',
      label: 'Загрузка только по ЛС / Договору (PDF)',
    },
  ]) || (dataType.value === 'oosProtocol' && [
    {
      key: 'useFileNameAddress',
      label: 'Загрузка только по адресу',
    },
  ]) || []).filter(Boolean));

  return {
    bottomActions,
  };
};
